import * as React from "react";
import { inject } from "mobx-react";
import { Viewport, ViewMedium, ViewSmall } from "@shared-ui/viewport-context";
import { decorateDestinationHeroHtml, Sources } from "../DestinationHeroUtilities";
import { WizardHeroWithHeadingsProps } from "../typings";
import { Media } from "typings/microserviceModels/destination-hero-flex-module";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkHeading, UitkText, UitkSubheading } from "@egds/react-core/text";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { BlossomImage, aimQualityMap, cropResizeSrc } from "src/components/shared/BlossomImage/BlossomImage";
import { imgDimension } from "../WizardHeroUtils";
import { convertAspectRatioToType } from "src/components/utility/DynamicMapUtils";

export const WizardHeroWithHeadings = inject(
  "wizardState",
  "compositionStore"
)((props: WizardHeroWithHeadingsProps) => {
  const { templateComponent, model, wizardState, compositionStore, context } = props;

  if (!templateComponent) {
    return null;
  }
  const {
    metadata: { id },
  } = templateComponent;
  const { content, imageHeight, imageRatio, mobileImageQuality, showTitle, widthLarge } = model;
  const { formatText } = useLocalization();

  const heading = compositionStore?.pageHeading;
  const subHeading = compositionStore?.pageSubHeadline;
  const hasHeadings = heading || subHeading;

  const firstImage = content && content.media && content.media[0];

  const fallbackImage: Media = {
    // Empty fields are required to adhere to our autogenerated typing for Media.
    cardIdentifier: "",
    mediaId: "",
    mediaUrl: "//media.expedia.com/media/content/shared/images/travelguides/sem-hotels-tablet.jpg",
    mediaUrlTrimmed: "",
    mediaUrlOriginal: "//media.expedia.com/media/content/shared/images/travelguides/sem-hotels-tablet.jpg",
    mediaAlt: "Default HTG hotel image",
    origin: "Expedia",
    source: "",
    author: "",
    authorUrl: "",
    license: { name: "", suite: "", url: "" },
    mediaType: "Image",
  };
  const selectedHeroImage =
    firstImage && !firstImage.mediaUrl.includes("TE_Clouds_card_r1.jpg") ? firstImage : fallbackImage;

  const { mediaUrl } = selectedHeroImage;
  if (wizardState) {
    wizardState.globalWizardState.config.heroImageSrc = mediaUrl;
  }

  const { source } = selectedHeroImage;
  const isTroverSource = source === Sources.Trover;
  const isWikimediaSource = source === Sources.Wikimedia;
  const isAttributionRequired = isTroverSource || isWikimediaSource;
  const isMobile = context && context.deviceInformation && context.deviceInformation.mobile;
  const optimizedImageDimensions = isMobile ? { width: 450, height: 280 } : { width: 1200, height: 300 };
  const quality = aimQualityMap[mobileImageQuality] ? mobileImageQuality : undefined;
  const optimizedImageUrl = cropResizeSrc(mediaUrl, optimizedImageDimensions, quality || "medium", false);

  const heroTitle = (): string => {
    if (!isWikimediaSource) {
      return "";
    }

    return formatText(
      "hero.attribution.wikimediaAlt",
      selectedHeroImage.mediaAlt,
      selectedHeroImage.author,
      selectedHeroImage.license && selectedHeroImage.license.name
    );
  };
  const imgStyle = {
    height: isMobile ? imgDimension(imageHeight) : undefined,
  };

  return (
    <div className="WizardHeroWithHeadings" id={id}>
      <UitkFigure ratio={convertAspectRatioToType(imageRatio, UitkFigureAspectRatioType.R4_1)} style={imgStyle}>
        <Viewport>
          <ViewSmall>
            <BlossomImage alt={heroTitle()} imageRatio={imageRatio} src={optimizedImageUrl} quality={quality} />
          </ViewSmall>

          <ViewMedium>
            <>
              <BlossomImage
                alt={heroTitle()}
                src={optimizedImageUrl}
                imageRatio={imageRatio}
                widthLarge={widthLarge}
                quality="high"
              />
              {showTitle && hasHeadings && (
                <UitkScrim type={UitkScrimType.BOTTOM}>
                  <UitkSpacing padding={{ inline: "six", block: "four" }}>
                    <UitkLayoutPosition type="relative" position={{ bottom: "zero" }}>
                      <div className="heroImageHeadings">
                        {heading && (
                          <UitkHeading tag="h1" size={2}>
                            {heading}
                          </UitkHeading>
                        )}
                        {subHeading && (
                          <UitkSpacing padding={{ blockstart: "two" }}>
                            <UitkSubheading tag="h2">{subHeading}</UitkSubheading>
                          </UitkSpacing>
                        )}
                      </div>
                    </UitkLayoutPosition>
                  </UitkSpacing>
                </UitkScrim>
              )}
            </>
          </ViewMedium>
        </Viewport>
      </UitkFigure>
      <AttributionText
        isAttributionRequired={isAttributionRequired}
        selectedHeroImage={selectedHeroImage}
        isTroverSource={isTroverSource}
      />
    </div>
  );
});

interface AttributionTextProps {
  isAttributionRequired: boolean;
  selectedHeroImage: Media;
  isTroverSource: boolean;
}

const AttributionText: React.FC<AttributionTextProps> = ({
  isAttributionRequired,
  selectedHeroImage,
  isTroverSource,
}): React.ReactElement | null => {
  const { formatText } = useLocalization();

  const { mediaUrlOriginal, author, authorUrl, license } = selectedHeroImage;
  const licenseSuite = license && license.suite;
  const licenseUrl = license && license.url;

  if (!isAttributionRequired || !author || author === "") {
    return null;
  }

  const htmlToDisplay = () => {
    if (isTroverSource) {
      const troverPhotoAnchor = formatText("hero.attribution.troverLink", author, mediaUrlOriginal);

      return decorateDestinationHeroHtml(troverPhotoAnchor);
    }

    const wikimediaPhotoAnchor =
      !authorUrl || authorUrl === ""
        ? formatText("hero.attribution.wikimediaLinkPlainAuthor", mediaUrlOriginal, author, licenseUrl, licenseSuite)
        : formatText("hero.attribution.wikimediaLink", mediaUrlOriginal, authorUrl, author, licenseUrl, licenseSuite);

    return decorateDestinationHeroHtml(wikimediaPhotoAnchor);
  };

  return (
    <UitkSpacing padding={{ inline: "three", block: "two" }}>
      <UitkText size={200}>
        <span dangerouslySetInnerHTML={htmlToDisplay()} />
      </UitkText>
    </UitkSpacing>
  );
};
