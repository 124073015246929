import * as React from "react";
import { useLocation } from "react-router-dom";
import { useDialog } from "@shared-ui/dialog-context";
import { useLocalization } from "@shared-ui/localization-context";
import { observer } from "mobx-react";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage, UitkImageLink } from "@egds/react-core/images";
import { UitkButton } from "@egds/react-core/button";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { UitkIcon } from "@egds/react-core/icons";
import { UitkToolbar } from "@egds/react-core/toolbar";
import { UitkSheet, UitkSheetContent, UitkSheetTransition } from "@egds/react-core/sheet";
import { UitkDialogAnimationType } from "@egds/react-core/dialog";
import {
  UitkMediaGalleryImageDialog,
  UitkMediaGalleryImageView,
  UitkMediaGalleryThumbnailView,
} from "@egds/react-core/media-gallery";
import { AttributionProps, GalleryImage, GalleryMosaicProps, GalleryMosaicThumbProps } from "../typings";
import { ItemKeyHelper } from "components/utility/ItemKeyHelper";
import { Action, FlexTrackingInfo, sendImmediateTrackEvent } from "components/utility/analytics/FlexAnalyticsUtils";
import { AttributionText } from "components/shared/AttributionText/AttributionText";
import { getFullScreenImages } from "components/utility/GalleryImageUtil";
import { TrackedVisibility } from "src/components/utility/analytics/TrackedVisibility";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";

const GalleryMosaic = (props: GalleryMosaicProps) => {
  const { analytics, images, locationId } = props;
  const galleryImages = getFullScreenImages(images);
  const { formatText } = useLocalization();
  const location = useLocation();
  const [currentMediaGalleryIndex, setCurrentMediaGalleryIndex] = React.useState(0);
  const [isMediaDialogOpen, mediaDialogActions, MediaGalleryDialogComponent] = useDialog(
    "gallery-open",
    "gallery-dialog"
  );
  const [isThumbnailsDialogOpen, thumbnailsDialogActions, ThumbnailsGalleryDialogComponent] = useDialog(
    "thumbnails-open",
    "thumbnails-dialog"
  );
  const triggerRef = React.useRef<HTMLElement>(null);
  const contentDomRef = React.createRef<HTMLDivElement>();

  const sendImageSeenTracking = (index: number) => {
    const mediaId = images[index]?.mediaId;

    if (mediaId) {
      const imageSeenTrackingInfo: FlexTrackingInfo = {
        moduleName: "GalleryMosaic",
        rfrr: "mosaic.gallery-image.seen",
        action: Action.IMPRESSION,
        linkName: `mediaId ${mediaId} image viewed at location ${locationId}`,
      };
      sendImmediateTrackEvent(imageSeenTrackingInfo, analytics);
    }
  };

  const setDialogGalleryIndex = (index: number) => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryMosaic",
      rfrr: "mosaic.gallery-nav.btn",
      action: Action.CLICK,
      linkName: "Navigating mosaic gallery viewer",
    };

    sendImmediateTrackEvent(trackingInfo, analytics);
    sendImageSeenTracking(index);
    setCurrentMediaGalleryIndex(index);
  };

  const openMediaGallery = (index: number) => {
    const mediaId = images[index]?.mediaId;
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryMosaic",
      rfrr:
        index < 5 && mediaId
          ? `mosaic.gallery.open.mediaId.${mediaId}.locationId.${locationId}`
          : "mosaic.gallery.open",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };

    sendImmediateTrackEvent(trackingInfo, analytics);
    sendImageSeenTracking(index);
    setCurrentMediaGalleryIndex(index);

    mediaDialogActions.openDialog();
  };

  const openThumbnailsGallery = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryMosaicThumbnails",
      rfrr: "mosaic.gallery.thumbnails.open",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateTrackEvent(trackingInfo, analytics);
    thumbnailsDialogActions.openDialog();
  };

  const closeMediaGallery = () => {
    const queryParams = new URLSearchParams(location.search);
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryMosaic",
      rfrr: "mosaic.gallery.close",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateTrackEvent(trackingInfo, analytics);

    mediaDialogActions.closeDialog();

    // Avoid focusing images that are not within the thumbnails dialog
    if (!queryParams.get("thumbnails-dialog")) {
      document.getElementById(`galleryMosaicThumb-${currentMediaGalleryIndex}`)?.focus();
    }
  };

  const closeThumbnailsGallery = () => {
    const trackingInfo: FlexTrackingInfo = {
      moduleName: "GalleryMosaicThumbnails",
      rfrr: "mosaic.gallery.thumbnails.close",
      action: Action.CLICK,
      linkName: "RFRR Action Event",
    };
    sendImmediateTrackEvent(trackingInfo, analytics);
    thumbnailsDialogActions.closeDialog();
    document.getElementById("galleryMosaicOpenButton")?.focus();
  };

  let thumbIndex = 0;
  const imageKeyHelper: ItemKeyHelper = new ItemKeyHelper("mosaic-image");

  const heroThumb = images && images.length && (
    <DestinationGalleryMosaicThumb
      key={imageKeyHelper.next()}
      position={thumbIndex}
      image={images[0]}
      onImageSelected={openMediaGallery}
      singleImage={images.length === 1}
      locationId={locationId}
    />
  );

  let thumbs: JSX.Element[];
  if (images && images.length) {
    thumbs = images.slice(1, 5).map((image: GalleryImage) => {
      thumbIndex++;

      return (
        <DestinationGalleryMosaicThumb
          key={imageKeyHelper.next()}
          position={thumbIndex}
          image={image}
          onImageSelected={openMediaGallery}
          locationId={locationId}
        />
      );
    });
  } else {
    thumbs = [];
  }

  const toolbarAttributes = {
    navIconLabel: formatText("carousel.close.image.gallery"),
  };

  return (
    <div>
      <UitkSheetTransition isVisible={isMediaDialogOpen}>
        <MediaGalleryDialogComponent>
          <UitkMediaGalleryImageDialog
            animation={UitkDialogAnimationType.NONE}
            dialogShow
            strictFullscreen
            onDismiss={closeMediaGallery}
            {...toolbarAttributes}
          >
            <UitkMediaGalleryImageView
              images={galleryImages}
              currentIndex={currentMediaGalleryIndex}
              onIndexChange={setDialogGalleryIndex}
            />
          </UitkMediaGalleryImageDialog>
        </MediaGalleryDialogComponent>
      </UitkSheetTransition>
      <UitkSheetTransition isVisible={isThumbnailsDialogOpen}>
        <ThumbnailsGalleryDialogComponent>
          <Viewport>
            <ViewSmall>
              <UitkSheet isVisible={isThumbnailsDialogOpen} type="full" triggerRef={triggerRef}>
                <UitkToolbar
                  toolbarTitle={props.title}
                  navigationContent={{
                    navIconLabel: formatText("destinationThumbnailsGallery.button.close"),
                    onClick: closeThumbnailsGallery,
                  }}
                />
                <UitkSheetContent>
                  <UitkMediaGalleryThumbnailView
                    images={props.images}
                    onImageSelected={openMediaGallery}
                    containerRef={contentDomRef}
                    focusIndex={currentMediaGalleryIndex}
                  />
                </UitkSheetContent>
              </UitkSheet>
            </ViewSmall>
            <ViewMedium>
              <UitkSheet isVisible={isThumbnailsDialogOpen} type="centered" triggerRef={triggerRef}>
                <UitkToolbar
                  toolbarTitle={props.title}
                  navigationContent={{
                    navIconLabel: formatText("destinationThumbnailsGallery.button.close"),
                    onClick: closeThumbnailsGallery,
                  }}
                />
                <UitkSheetContent>
                  <UitkMediaGalleryThumbnailView
                    images={props.images}
                    onImageSelected={openMediaGallery}
                    containerRef={contentDomRef}
                    focusIndex={currentMediaGalleryIndex}
                  />
                </UitkSheetContent>
              </UitkSheet>
            </ViewMedium>
          </Viewport>
        </ThumbnailsGalleryDialogComponent>
      </UitkSheetTransition>
      <div className={`GalleryMosaic GalleryMosaic--${thumbs.length + 1}`}>
        <div className="galleryMosaicHero">{heroThumb}</div>

        <div className="galleryMosaicThumbs" aria-hidden>
          {thumbs}
        </div>

        <UitkScrim className="galleryMosaicScrim" type={UitkScrimType.BOTTOM}>
          <UitkButton
            domRef={triggerRef}
            id="galleryMosaicOpenButton"
            onClick={openThumbnailsGallery}
            className="galleryMosaicButton"
          >
            <span aria-hidden="true">{props.images.length}</span>
            <UitkIcon
              name="photo_library"
              title={formatText("galleryMosaic.seeAllTitle", props.images.length)}
              id="photo_library"
              shadow
            />
          </UitkButton>
        </UitkScrim>
      </div>
    </div>
  );
};

const Attribution = observer((attributionProps: AttributionProps) => {
  const { image } = attributionProps;

  if (!image.source) {
    return null;
  }

  return (
    <AttributionText
      source={image.source}
      origin={image.origin}
      attribUrl={image.attribUrl}
      authorName={image.authorName}
      authorUrl={image.authorUrl}
      licenseSuite={image.licenseSuite}
      licenseName={image.licenseName}
      licenseUrl={image.licenseUrl}
    />
  );
});

export const DestinationGalleryMosaicThumb = observer((props: GalleryMosaicThumbProps) => {
  const { image, singleImage, locationId } = props;

  const onImageSelected = () => {
    props.onImageSelected(props.position);
  };

  const galleryMosaicThumbElement = (
    <UitkFigure ratio={singleImage ? UitkFigureAspectRatioType.R21_9 : UitkFigureAspectRatioType.R16_9}>
      <UitkImage src={image.src} alt={image.alt} placeholderImage />
      <UitkImageLink>
        <button
          onClick={onImageSelected}
          data-testid={`galleryMosaicThumb-${props.position}`}
          id={`galleryMosaicThumb-${props.position}`}
        >
          {image.alt}
        </button>
      </UitkImageLink>
      <Attribution image={image} />
    </UitkFigure>
  );

  const rffrId = `imageId.${image.mediaId}.locationId.${locationId}`;

  if (image.mediaId) {
    return (
      <div className="galleryMosaicThumb">
        <TrackedVisibility rfrrId={rffrId} moduleName="GalleryMosaicThumb">
          {galleryMosaicThumbElement}
        </TrackedVisibility>
      </div>
    );
  } else {
    return <div className="galleryMosaicThumb">{galleryMosaicThumbElement}</div>;
  }
});

export default GalleryMosaic;
